<template lang="pug">
div 
    ejs-dialog(v-if='editorShown' ref="dialogObjTaskEditor" id="dialogTaskEditor" allowDragging="true" width="500" :showCloseIcon="true" :header="isNew ? 'Créer un évènement' : 'Modifier un évènement'"  :footerTemplate="'footerDialog'" :close="closeDialog")  
        v-style
          | .e-dialog .e-dlg-header-content {  {{(eventForm.color ? 'background:'+eventForm.color+'50  !important' : 'background:#f8f8f8  !important')}} }
        div(style="background:#f8f8f8")
          div.px-0(:style="(eventForm.color ? 'background:'+eventForm.color+'50  !important' : 'background:#f8f8f8  !important') + '; border-bottom: 1px solid #dee2e6;'")
            b-row.content-scrollable-sticky.m-0.h-100.justify-content-center
                b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
                    ul.d-flex
                      li
                        a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'general' ? 'current' : ''" @click="tabActive = 'general'")
                            span Général
                      li
                        a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                            span Détails
                      li(v-show="eventForm.collaborators && eventForm.collaborators.length>0")
                        a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notification' ? 'current' : ''" @click="tabActive = 'notification'")
                            span Notification
                      li(style="position: absolute; right:0px")
                        a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'settings' ? 'current' : ''" @click="tabActive = 'settings'")
                            span.material-icons-outlined(style="font-size: 21px !important;") settings
        div.p-1(v-show="tabActive == 'general'")
          b-row.mb-50.mt-0
            b-col.py-0(cols='12')
                input(type="hidden" name="type" id="type" v-model="eventForm.type")
                b-form-group(label='Type d\'évènement *' label-for='eventType')
                    ejs-dropdownlist(id="eventType" name="eventType" placeholder="Selectionnez un type d\'évènement" :itemTemplate="'itemTemplate'" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="eventTypesListFiltered" :fields="{ text: 'label', value: 'id' }" v-model="eventForm.eventTypeId" @change='changeEventType')
                        template(v-slot:itemTemplate='{data}')
                            .d-flex
                                div.pin-color(:style="'background-color:'+(data.color!=='' ? data.color : '#0c3571')+' !important'")
                                div {{data.label}}
                        template(v-slot:footerTemplate)
                            div.p-50
                                ejs-button(v-on:click.native="newEventType()" :content="'+ Nouveau type d’évènement'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right") 
          b-row.mb-50(v-show="eventForm.eventType && eventForm.eventType.isDependent")
            b-col.py-0(cols='12')
              b-form-group(label='Client' label-for='companyId')
                ejs-dropdownlist( id="companyId" name="company" placeholder="Selectionnez un client" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="companiesList" :fields="{ text: 'name', value: 'id' }" v-model="eventForm.companyId" :showClearButton="true" @change='changeCompany')
                  template(v-slot:footerTemplate)
                    div.p-50
                      ejs-button(v-on:click.native="newCompany()" :content="'+ Nouveau client'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")      
          b-row.mb-50(v-show="eventForm.eventType && eventForm.eventType.isDependent")
            b-col.py-0(cols='12')
              b-form-group(label='Affaire' label-for='eventAffairId')
                ejs-dropdownlist(ref="dropdownlistAffairs" id="eventAffairId" name="affair" placeholder="Selectionnez une affaire" :itemTemplate="'itemTemplate'" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="affairsList" :fields="{ text: 'displayLabel', value: 'id' }" v-model="eventForm.affairId" :showClearButton="true" @change='changeAffair' @open="filterAffairsList")
                  template(v-slot:itemTemplate='{data}')
                    .d-flex(:id="'taskeditAffair_'+data.id")
                      div.pin-color(:style="'background-color:'+(data.color!=='' ? data.color : '#0c3571')+' !important'")
                      div {{data.displayLabel}}
                  template(v-slot:footerTemplate)
                    div.p-50
                      ejs-button(v-on:click.native="newAffair()" :content="'+ Nouvelle affaire'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
                div(id="affairIdError")

          b-row.mb-50(v-show="eventForm.eventTypeId && eventForm.companyId && eventForm.affairId && missionsList.length>0")
            b-col.py-0(cols='12')
                b-form-group(label='Mission' label-for='missionId')
                  ejs-dropdowntree(id='missionId' popupHeight='300px' filterType='Contains' :allowFiltering='true' :fields="missionFields" v-model='eventForm.missionId' @change='changeMission')
          b-row.mb-50
            b-col.py-0(cols='12')
              b-form-group(label='Nom' label-for='name')
                  ejs-textbox(id="name" v-model='eventForm.name' placeholder="Titre de l'évènement")
          b-row.mb-50
            b-col.py-0(cols='12')
              //- pre {{  planningLinesCollaboratorList }} 
              b-form-group 
                ejs-multiselect(id='searchRessources' v-model='eventForm.ressources' :fields='customFields' :dataSource='ressourcesFiltered' mode="Box"  cssClass="mb-0" :showDropDownIcon="true"  placeholder='Affecté à' floatLabelType="Always" sortOrder="Ascending" filterType='Contains')

              //- pre {{  ressourcesFiltered }}
          b-row.mb-50
              b-col.py-0(cols='6')
                  b-form-group(label='Début' label-for='startDate') 
                      ejs-datetimepicker(id="startDate" locale='fr' v-model="eventForm.startDate" :showClearButton="false" :firstDayOfWeek="1" @change="changeDate") 
              b-col.py-0(cols='6' v-if="endDateLoaded")
                  b-form-group(label='Fin' label-for='endDate') 
                      ejs-datetimepicker(id="endDate" locale='fr' v-model="eventForm.endDate" :showClearButton="false" :firstDayOfWeek="1" @change="changeDate")  
          b-row.mb-50
              b-col.py-0(cols='6')
                  b-form-group(label='Durée en heure / jour' label-for='hoursDay') 
                      ejs-numerictextbox(id="hoursDay" :enabled="!durationDisabled" :min=0 :step="planningSettingTimeStep" :format='"##.## h"' v-model="eventForm.hoursDay")              
          b-row.mb-0
            b-col.py-0.mb-0(cols='12')
              vs-divider(color="#cccccc" class="m-0 ml-0 pl-0 mt-0 mb-0" position="left")
                .d-flex.align-items-center
                  label.mb-0 Détails par jour
                  div.ml-50(v-if="eventForm.eventDetails && eventForm.eventDetails.length>0")
                    ejs-button(v-on:click.native="deleteEventDetails"  content='' small cssClass='e-outline e-primary m-0 e-small' iconCss='e-btn-sb-icons e-icon-trash'   :isPrimary="true" locale='fr')
              div.w-100.mt-50(v-if="!eventDetailsFormated || (eventDetailsFormated && eventDetailsFormated.length<=0)")
                ejs-button(v-on:click.native="generateEventDetails"  content='Gérer le détail par jour' cssClass='e-outline e-primary m-0 w-100'  :isPrimary="true" locale='fr')
              div.w-100(v-else)
                table.w-100.table-striped
                  tr
                    th.text-left.pl-25(style="min-width: 88px;") Jour
                    th.text-center.pr-25(v-for="(item, index) in planningSettingsSlot" :key="index")
                      .w-100.d-flex.justify-content-between.align-items-center
                        .d-flex.align-items-center Durée (h/{{item.shortLabel}})
                        .d-flex.justify-content-end.align-items-center 
                          span.material-icons-outlined.m-0.ml-50.rounded.border.text-gray-700.p-25.cursor-pointer(style="font-size:15px" @click="removeHourAllDays(item.shortLabel)") horizontal_rule
                          span.material-icons-outlined.m-0.ml-50.rounded.border.text-gray-700.p-25.cursor-pointer(style="font-size:15px" @click="addHourAllDays(item.shortLabel)") add
                  tr(v-for="(item, index) in eventDetailsFormated" :key="index")
                    td.pl-25 {{ formatDateDay(item[0].Date) }}
                    td(v-for="(itemEventDetail, indexEventDetail) in item" :key="indexEventDetail")
                      .pl-50.my-25 
                        ejs-numerictextbox(id="hoursDay" :step="planningSettingTimeStep" :min=0  :format='"##.## h"' v-model="itemEventDetail.Duration")    
        div.p-1(v-show="tabActive == 'details'")
          b-row.pt-1.mb-50
            b-col.py-0(cols='12')
              b-form-group 
                //- label(label-for='comments' style='font-weight: 600;') Description/Note :
                b-form-textarea(id="comments" v-model="eventForm.comments" rows="8" @input="changeComments")
        div.p-1(v-show="tabActive == 'settings'")
          b-row.pt-1.mb-50
              b-col.py-0.d-flex(cols='12')
                  span(class="link_icon material-icons-outlined m-0 mr-50 pt-1" style="font-size:15px")
                      | lock
                  b-form-group(label="Interdire les autres utilisateurs de modifier cet évènement" label-cols="10")
                      b-form-checkbox(v-model="eventForm.isLocked" class="mr-0" name="isLocked" switch inline @change="changeIsLocked")
              b-col.py-0.d-flex(cols='12')
                  span(class="link_icon material-icons-outlined m-0 mr-50 pt-1" style="font-size:15px")
                      | visibility_off
                  b-form-group(label="Masquer le détail de cet évènement et le bloquer auprès des autres utilisateurs" label-cols="10")
                      b-form-checkbox(v-model="eventForm.isHidden" class="mr-0" name="isHidden" switch inline @change="changeIsHidden")
        div.p-1(v-show="tabActive == 'notification'")
          b-row.pt-1.mb-0(v-if="smsListByEventId && smsListByEventId.length>0")
            b-col.py-0.mb-0(cols='12')
              vs-divider(color="#cccccc" class="m-0 ml-0 pl-0 mt-0 mb-50" position="left")
                label Historique des SMS
              ul.app-timeline
                li.timeline-item.timeline-variant-secondary(v-for="(item, index) in smsListByEventId" :key="index" :time="formatDate(item.sendAt)" variant="secondary")
                  div.timeline-item-point
                  div
                    div.d-flex.flex-sm-row.flex-column.flex-wrap.align-items-center.justify-content-between.mb-50
                      h6.mb-0 {{ formatDateHour(item.sendAt) }} 
                      span.timeline-item-time.text-nowrap.text-secondary {{ item.collaborator.firstName + ' ' + item.collaborator.lastName  }} ( {{  item.collaborator.phoneNumber }} )
                    p.mb-1.timeline-content {{ item.message }}
          b-row.pt-0.mt-0.mb-0 
              .w-100
                b-col.py-0(cols='12')
                  vs-divider(color="#cccccc" class="m-0 ml-0 pl-0 mt-0 mb-50" position="left")
                    label Envoyer une notification
                b-col.py-0(cols='12' v-for="(collaborator, index) of eventForm.collaborators" :key="index")
                  .w-100.d-flex.justify-space-between.pb-25(style="justify-content: space-between; line-height: 32px; border-bottom: 1px solid #f1f1f1;")
                      .d-flex
                          | {{collaborator.firstName + ' ' + collaborator.lastName}}
                      .d-flex
                          span.mr-1 | {{collaborator.phoneNumber}}
                          ejs-button(v-on:click.native="sendWhatApp(collaborator.phoneNumber)" :disabled="!collaborator.phoneNumber" :content="'WhatsApp'" :isPrimary="true" locale='fr' cssClass='e-outline e-small mr-50')
                          ejs-button(v-on:click.native="sendEmail(collaborator.email)" :disabled="!collaborator.email"  :content="'E-mail'" :isPrimary="true" locale='fr' cssClass='e-outline e-small' :iconCss="'e-btn-sb-icons e-icons e-send'" iconPosition="Right")
                          //- .d-flex.ml-1
                          //-   | Envoyer un whatsapp
                          //-   img.cursor-pointer.ml-50.mr-50.text-primary(src="@/assets/icones/icon-whatsapp.svg" @click="sendWhatApp(collaborator.phoneNumber)" style="width: 16px;")
                          //-   span.material-icons-outlined(style="font-size: 16px !important;") settings
              b-col.py-0(cols='12')
                  vs-divider(color="#cccccc" class="m-0 ml-0 pl-0 mt-1 mb-50" position="left")
                    label Programmer un SMS
              b-col.py-0.mb-0(cols='12' v-if="eventForm.notificationSmsSuccess") 
                  b-alert.mt-0(v-if="!eventForm.id" variant='success' style='margin-top:0px; margin-bottom:0px; box-shadow: none !important;' show)
                      div.alert-heading(style="box-shadow: none !important;")
                          div.mb-50
                              p(style="font-size: 14px; font-weight: 500; line-height: 14px;") Votre SMS sera programmé lorsque vous aurez enregistré votre événément !
                  b-alert.mt-0(v-else variant='success' style='margin-top:0px; margin-bottom:0px; box-shadow: none !important;' show)
                      div.alert-heading(style="box-shadow: none !important;")
                          div.mb-50
                              p(style="font-size: 14px; font-weight: 500; line-height: 14px;") Votre SMS a été correctement programmé !
                          div
                              p(style="font-size: 12px;") Vous pouvez à tout moment effectuer un nouvel envoi et consulter l'historique de vos SMS depuis l'onglet 'Paramètres > Gestion des SMS'
              b-col.py-0.mb-0(cols='12' v-else-if="!eventForm.id") 
                  b-alert.mt-0(variant='warning' style='margin-top:0px; margin-bottom:0px; box-shadow: none !important;' show)
                      div.alert-heading(style="box-shadow: none !important;")
                          div.mb-50
                              p(style="font-size: 14px; font-weight: 500; line-height: 14px;") Pour programmer un SMS vous devez d'abord enregistrer votre événement.
              
          b-row.mb-50(v-if="!eventForm.notificationSmsSuccess && eventForm.id") 
              b-col.py-0(cols='12')
                b-form-group(label="Date et heure d'envoi" label-for='notificationSmsDate') 
                  ejs-datetimepicker(id="notificationSmsDate" locale='fr' v-model="eventForm.notificationSmsDate" :firstDayOfWeek="1" :min="today" :step="5" :showClearButton="false")  
                //b-form-group 
                    label.w-100(label-for='notificationSmsDate' style='font-weight: 600;') Date et heure d'envoi :
                    date-picker.w-100(v-model="eventForm.notificationSmsDate" type="datetime" :minute-step="5" format="DD/MM/YYYY HH:mm" :disabled-date="notBeforeToday" :disabled-time="notBeforeTodayNowOClock" :clearable="false" @input="verifyDate")
          b-row.mb-50(v-if="!eventForm.notificationSmsSuccess && eventForm.id") 
              b-col.py-0(cols='12')
                  b-form-group(label="Texte du SMS" label-for='notificationSmsText')
                      ejs-textbox(id="notificationSmsText" v-model='eventForm.notificationSmsText' :multiline="true")
          b-row.mb-50(v-if="!eventForm.notificationSmsSuccess && eventForm.id") 
              b-col.py-0(cols='12')
                  b-button.w-100(variant='outline-primary' @click="sendSms()" :disabled="!eventForm.notificationSmsText")
                      | Programmer le SMS
                      feather-icon.ml-1(icon="SendIcon")
          //- b-row.mb-50  
          //-     b-col.py-0(cols='12')
          //-       b-button.w-100(variant='outline-primary' @click="sendWhatApp()")
          //-         | Envoyer par WhatsApp
          //-         feather-icon.ml-1(icon="SendIcon")
                //- ejs-button(v-on:click.native="sendWhatApp" :content="'Envoyer par WhatsApp'" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")
        template(v-slot:footerDialog='{data}')
          .w-100.mx-0.px-25.d-flex.justify-content-between.align-items-center
            div
              ejs-button(v-on:click.native="removeEvent" v-if="!isNew" content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-trash'  :isPrimary="true" locale='fr')
            ejs-button(v-on:click.native="saveHandler" :disabled="!eventForm.eventTypeId" :content="eventForm.id ? 'Enregistrer' : 'Ajouter'" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fr";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import { Timezone } from "@syncfusion/ej2-schedule";
let timezoneSchedule = new Timezone();

import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  components: {
    DatePicker,
  },
  props: {
    value: [Boolean, Object],
    eventRecord: Object,
    eventStore: Object,
    resourceId: [String, Number],
    resourceTypeLine: Number,
  },
  data() {
    return {
      timelineItems: [],
      githubRoadmap: [
        {
          icon: "sf-icon-commit",
          message: "Created 10 commits in 5 repositories",
        },
        { icon: "sf-icon-create", message: "Created 1 repository" },
        {
          icon: "sf-icon-pull",
          message:
            "Created a pull request in <u>organization/new-control-roadmap</u>",
        },
        {
          icon: "sf-icon-review",
          message: "Reviewed 3 pull requests in 2 repositories",
        },
      ],

      tabActive: "general",
      isNew: 0,
      showTimePanelStart: false,
      showTimePanelEnd: false,
      customFields: { text: "label", value: "id", color: "color" },
      itemTemplate:
        "<span id:'${id}' class='e-chips text-white' style='padding: 2px 6px 2px 8px; border-radius: 4px; background-color: ${color};'>${label}</span>",
      eventForm: {
        name: "",
        startDate: null,
        endDate: null,
        eventDetails: null,
        planningLineId: null,
        eventType: null,
        eventTypeId: 0,
        affair: null,
        affairId: null,
        mission: null,
        missionId: null,
        company: null,
        companyId: null,
        isHidden: 0,
        isLocked: 0,
        hoursDay: 0,
        comments: null,
        notificationSmsText: null,
        notificationSmsDate: null,
        notificationSmsSuccess: false,
        cls: null,
        id: null,
        collaboratorResourceIds: null,
        affairResourceId: null,
      },
      cacheSms: null,
      endDateLoaded: true,
    };
  },
  computed: {
    ...mapGetters([
      "moduleParametersList",
      "isLoadingEventTypesList",
      "eventTypesList",
      "isLoadingCompaniesList",
      "companiesList",
      "isLoadingAffairsList",
      "affairsList",
      "planningView",
      "collaboratorsList",
      "connectedUser",
      "missionsList",
      "smsListByEventId",
      "planningLinesCollaboratorList"
    ]),
    planningSettingUseColorAffair() {
      return this.moduleParametersList.find((el) => el.key == "useColorAffairs")
        ? JSON.parse(
            this.moduleParametersList.find((el) => el.key == "useColorAffairs")
              .value
          )
        : false;
    },
    planningSettingTimeStep() {
      return this.moduleParametersList.find((el) => el.key == "timeStep")
        ? JSON.parse(
            this.moduleParametersList.find((el) => el.key == "timeStep").value
          )
        : 0.5;
    },
    planningSettingsSlot() {
      return this.moduleParametersList.find((el) => el.key == "slots")
        ? JSON.parse(
            this.moduleParametersList.find((el) => el.key == "slots").value
          )
        : [
            {
              shortLabel: "J",
              label: "Jour",
            },
          ];
    },
    missionFields() {
      return {
        dataSource: this.missionsList.map((mission) => {
          return {
            ...mission,
            parentId: mission.parentId || null,
            hasChildren: this.$store.getters.missionsList.some(
              (m) => m.parentId == mission.id
            ),
          };
        }),
        value: "id",
        text: "label",
        parentValue: "parentId",
        hasChildren: "hasChildren",
      };
    },
    today() {
      return new Date();
    },
    durationDisabled() {
      const diff = dayjs(this.eventForm.endDate).diff(
        dayjs(this.eventForm.startDate),
        "hours",
        true
      );
      if (diff >= 24) {
        return false;
      } else {
        return true;
      }
    },
    eventDetailsFormated() {
      return this.eventForm.eventDetails &&
        this.eventForm.eventDetails.length > 0
        ? this.eventForm.eventDetails.reduce((acc, obj) => {
            const date = dayjs(obj.Date).format("YYYY-MM-DD");
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(obj);
            return acc;
          }, {})
        : [];
    },
    affairsListFiltered: {
      get() {
        if (this.eventForm.company) {
          let listFiltered = this.affairsList.filter(
            (el) => el.company && el.company.id == this.eventForm.company.id
          );
          if (listFiltered.length == 1) {
            this.eventForm.affair = listFiltered[0];
            this.eventForm.affairId = listFiltered[0].id;
            //this.eventForm.name = this.eventForm.name + ' / ' +listFiltered[0].name
          }
          return listFiltered;
        } else {
          return this.affairsList;
        }
      },
    },
    eventTypesListFiltered: {
      get() {
        if (this.resourceTypeLine == 1) {
          return this.eventTypesList.filter((el) => el.isDependent === true);
        } else {
          return this.eventTypesList;
        }
      },
    },
    ressourcesFiltered: {
      get() {
        // if(this.resourceTypeLine==1 ){
        //   return this.planningLinesCollaboratorList.map((el)=> { 
        //     return {
        //       id: el.id,
        //       name:el.name,
        //       isLocked: false,
        //       typeLine: 1,
        //       planningLineId: el.id,
        //       collaboratorId: el.collaboratorId,
        //       label: el.name
        //     }
        //   })

        // }else
         if (this.planningView && this.planningView.linePositions) {
          return this.planningView.linePositions
            .filter((el) => el.typeLine == 0)
            .map((el) => {
              let ressourceLabel = el.name.split("/");
              return { ...el, label: ressourceLabel[0] };
            });
        }
      },
    },
    editorShown: {
      get() {
        const me = this,
          eventRecord = me.eventRecord;
        if (true === me.value) {
          if (
            eventRecord &&
            me.eventTypesList &&
            me.companiesList &&
            me.affairsList &&
            me.planningView &&
            me.collaboratorsList
          ) {
            let eventColor = null;
            let eventCls = null;
            if (
              me.eventRecord.UpdatedBy &&
              typeof this.eventRecord.id === "number" &&
              !isNaN(this.eventRecord.id)
            ) {
              me.isNew = 0;
              if (
                this.planningSettingUseColorAffair &&
                eventRecord.AffairId &&
                me.affairsList.find((el) => el.id == eventRecord.AffairId)
                  ?.color
              ) {
                eventColor = me.affairsList.find(
                  (el) => el.id == eventRecord.AffairId
                ).color;
              } else if (
                this.planningSettingUseColorAffair &&
                eventRecord.CompanyId &&
                me.companiesList.find((el) => el.id == eventRecord.CompanyId)
                  ?.color
              ) {
                eventColor = me.companiesList.find(
                  (el) => el.id == eventRecord.CompanyId
                ).color;
              } else if (eventRecord.EventTypeId) {
                eventColor = me.eventTypesList.find(
                  (el) => el.id == eventRecord.EventTypeId
                ).color;
              } else {
                eventColor = null;
              }
              if (eventColor) {
                eventCls =
                  "background-color:" + eventColor.slice(0, 7) + " !important";
              }
              this.getHistorySmsByEventId({
                eventId: this.eventRecord.id,
              });
            } else {
              me.isNew = 1;
              eventRecord.HoursDay =
                dayjs(this.eventRecord.endDate).diff(
                  dayjs(this.eventRecord.startDate),
                  "hours",
                  true
                ) >= 24
                  ? 7
                  : dayjs(this.eventRecord.endDate).diff(
                      dayjs(this.eventRecord.startDate),
                      "hours",
                      true
                    );
              this.$store.commit("SET_SMS_LIST_BY_EVENT_ID", []);
            }

            me.cacheSms = null;
            let ressourcesMap = [];
            let collaboratorIds = [];
            let collaborators = [];
            let affair = null;
            let company = null;

            eventRecord.assignments.forEach((element) => {
              let ressource = me.planningView.linePositions
                .map((el) => {
                  let ressourceLabel = el.name.split("/");
                  return { ...el, label: ressourceLabel[0] };
                })
                .find((el) => el.id == element.resourceId);
              ressourcesMap.push(ressource.id);
              if (ressource.collaboratorId > 0 && ressource.typeLine == 0) {
                collaboratorIds.push(ressource.collaboratorId);
              }
              if (ressource.typeLine === 1 && ressource.affairId) {
                affair = me.affairsList.find(
                  (el) => el.id === ressource.affairId
                );
                if (affair && affair.company) {
                  company = affair.company;
                }
              }
            });

            collaborators = me.collaboratorsList.filter(
              (el) =>
                collaboratorIds.includes(el.id) &&
                el.phoneNumber !== null &&
                el.phoneNumber !== ""
            );
            this.initialize();

            Object.assign(me, {
              tabActive: "general",
              eventForm: {
                assignments: eventRecord.assignments,
                collaborators: collaborators,
                ressources: ressourcesMap,
                name: eventRecord.name,
                startDate: eventRecord.startDate,
                endDate: eventRecord.endDate,
                eventDetails: eventRecord.eventDetails,
                planningLineId: eventRecord.PlanningLineId,
                eventTypeId: eventRecord.EventTypeId,
                eventType: me.eventTypesList.find(
                  (el) => el.id == eventRecord.EventTypeId
                ),
                missionId: eventRecord.MissionId
                  ? [eventRecord.MissionId.toString()]
                  : null,
                mission: me.missionsList.find(
                  (el) => el.id == eventRecord.MissionId
                ),
                affairId: affair ? affair.id : eventRecord.AffairId,
                affair: affair
                  ? affair
                  : me.affairsList.find((el) => el.id == eventRecord.AffairId),
                companyId: company ? company.id : eventRecord.CompanyId,
                company: company
                  ? company
                  : me.companiesList.find(
                      (el) => el.id == eventRecord.CompanyId
                    ),
                isHidden: eventRecord.IsHidden,
                isLocked: eventRecord.IsLocked,
                hoursDay: eventRecord.HoursDay,
                comments: eventRecord.Comments,
                cls: eventCls ? eventCls : eventRecord.cls,
                notificationSmsText: eventRecord.Comments,
                notificationSmsDate:
                  this.getCurrentTimeRoundedToNextMultipleOf5(),
                notificationSmsSuccess: false,
                id: me.eventRecord.UpdatedBy ? me.eventRecord.id : null,
                color: eventColor ? eventColor.slice(0, 7) : null,
              },
              durationByDay: eventRecord.HoursDay,
            });

            if (
              (eventRecord.id && !eventRecord.eventDetails) ||
              !eventRecord.id
            ) {
              //this.generateEventDetails()
            }
            //this.$refs.dialogObjTaskEditor.show();
          }
        }
        return !!this.value;
      },
      set(value) {
        const { eventRecord, eventStore } = this;
        if (eventRecord.isCreating) {
          eventStore.remove(eventRecord);
          eventRecord.isCreating = false;
        }
        this.$emit("input", value);
        this.initialize();
      },
    },
  },

  async created() {
    await this.getMissions({});
  },
  mounted() {
    this.timelineItems = this.githubRoadmap.map(({ icon, message }) => ({
      dotCss: icon,
      content: message,
    }));
  },
  methods: {
    ...mapActions(["programSms", "getHistorySmsByEventId", "getMissions"]),

    filterAffairsList(args) {
      for (let index = 0; index < this.affairsList.length; index++) {
        const element = this.affairsList[index];
        if (
          !element.company ||
          (element.company.id !== this.eventForm.companyId &&
            this.eventForm.companyId)
        ) {
          document.querySelector(
            "#taskeditAffair_" + element.id
          ).parentElement.style.display = "none";
        } else {
          document.querySelector(
            "#taskeditAffair_" + element.id
          ).parentElement.style.display = "flex";
        }
      }
    },
    formatDate,
    formatDateDay(str) {
      let dateStr = dayjs(str).format("dddd DD");
      return dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
    },
    formatDateHour(str) {
      return dayjs(str).format("DD/MM/YYYY HH:mm");
    },
    initialize() {
      this.eventForm = {
        name: "",
        startDate: null,
        endDate: null,
        eventDetails: null,
        planningLineId: null,
        eventType: null,
        eventTypeId: 0,
        affair: null,
        affairId: null,
        mission: null,
        missionId: null,
        company: null,
        companyId: null,
        isHidden: 0,
        isLocked: 0,
        hoursDay: 0,
        comments: null,
        notificationSmsText: null,
        notificationSmsDate: null,
        notificationSmsSuccess: false,
        cls: null,
        id: null,
      };
    },
    verifyDate(args) {
      var minutes = args.getMinutes();
      if (minutes % 5 !== 0) {
        this.eventForm.notificationSmsDate = this.roundToNextMultipleOf5(args);
      }
    },
    roundToNextMultipleOf5(date) {
      var minutes = date.getMinutes();
      var remainder = minutes % 5;
      if (remainder !== 0) {
        minutes += 5 - remainder;
      }
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        minutes
      );
    },
    getCurrentTimeRoundedToNextMultipleOf5() {
      var currentDate = new Date();
      return this.roundToNextMultipleOf5(currentDate);
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeTodayNowOClock(date) {
      return date < new Date(new Date());
    },
    changeComments(event) {
      this.eventForm.notificationSmsText = event;
    },
    changeIsLocked(event) {
      if (!event && this.eventForm.isHidden) this.eventForm.isHidden = false;
    },
    changeIsHidden(event) {
      if (event) this.eventForm.isLocked = true;
    },

    changeEventType(event) {
      if (event.itemData) {
        this.eventForm.eventType = event.itemData;
        this.eventForm.eventTypeId = event.itemData.id;
        this.eventForm.cls =
          "background-color:" + event.itemData.color + " !important";
        this.eventForm.color = event.itemData.color.slice(0, 7);
        this.eventForm.name = event.itemData.label;
        //- if (!event.itemData.isDependent) {
        //-   this.eventForm.company = null;
        //-   this.eventForm.companyId = null;
        //-   this.eventForm.affair = null;
        //-   this.eventForm.affairId = null;
        //-   this.eventForm.mission = null;
        //-   this.eventForm.missionId = null;
        //- }
      }
      this.$forceUpdate();
    },
    changeCompany(event) {
      if (event.itemData) {
        this.eventForm.name = event.itemData.name;
        if (this.planningSettingUseColorAffair) {
          this.eventForm.cls =
            "background-color:" +
            (event.itemData.color
              ? event.itemData.color.slice(0, 7)
              : this.eventForm.company.color.slice(0, 7)) +
            " !important";
          this.eventForm.color = event.itemData.color
            ? event.itemData.color.slice(0, 7)
            : this.eventForm.company.color.slice(0, 7);
        }

        if (this.eventForm.company !== event.itemData && event.e) {
          this.eventForm.mission = null;
          this.eventForm.missionId = null;
        }
        let listFiltered = this.affairsList.filter(
          (el) => el.company && el.company.id == this.eventForm.companyId
        );

        if (listFiltered.length == 1) {
          this.eventForm.affair = listFiltered[0];
          this.eventForm.affairId = listFiltered[0].id;
        } else if (
          !listFiltered.find((el) => el.id == this.eventForm.affairId) ||
          !listFiltered.length > 1
        ) {
          this.eventForm.affair = null;
          this.eventForm.affairId = null;
        }
        this.eventForm.company = event.itemData ? event.itemData : null;
      } else if (event.e) {
        this.eventForm.company = null;
        this.eventForm.companyId = null;
        this.eventForm.affair = null;
        this.eventForm.affairId = null;
        this.eventForm.mission = null;
        this.eventForm.missionId = null;
      }
    },
    changeAffair(event) {
      if (event.itemData) {
        this.eventForm.affair = event.itemData ? event.itemData : null;
        this.eventForm.companyId =
          event.itemData && event.itemData.company
            ? event.itemData.company.id
            : this.eventForm.companyId
            ? this.eventForm.companyId
            : null;
        this.eventForm.name =
          this.eventForm.company.name +
          " / " +
          (event.itemData.code ? event.itemData.code : event.itemData.name);
        if (this.planningSettingUseColorAffair) {
          this.eventForm.cls =
            "background-color:" +
            (event.itemData.color
              ? event.itemData.color.slice(0, 7)
              : this.eventForm.eventType.color.slice(0, 7)) +
            " !important";
          this.eventForm.color = event.itemData.color
            ? event.itemData.color.slice(0, 7)
            : this.eventForm.eventType.color.slice(0, 7);
        }
      } else if (event.e) {
        this.eventForm.affair = null;
        this.eventForm.affairId = null;
        this.eventForm.mission = null;
        this.eventForm.missionId = null;
      }
    },
    changeMission(event) {
      this.eventForm.name =
        (this.eventForm.company ? this.eventForm.company.name : "") +
        (this.eventForm.affair
          ? " / " +
            (this.eventForm.affair.code
              ? this.eventForm.affair.code
              : this.eventForm.affair.name)
          : "") +
        (event.element.value ? " / " + event.element.value : "");
      this.$forceUpdate();
    },
    changeRessources(event) {
      let collaboratorIds = [];
      event.forEach((element) => {
        if (element.collaboratorId > 0 && element.typeLine == 0) {
          collaboratorIds.push(element.collaboratorId);
        }
      });
      this.eventForm.collaborators = this.collaboratorsList.filter(
        (el) =>
          collaboratorIds.includes(el.id) &&
          el.phoneNumber !== null &&
          el.phoneNumber !== ""
      );
    },
    deleteEventDetails() {
      this.eventForm.eventDetails = [];
      this.$forceUpdate();
    },
    generateEventDetails() {
      // Initialiser les dates avec dayjs
      let start = dayjs(dayjs(this.eventForm.startDate).format("YYYY-MM-DD"));
      const end = dayjs(dayjs(this.eventForm.endDate).format("YYYY-MM-DD"));

      // Vérifier que la date de début est avant ou égale à la date de fin
      if (start.isAfter(end)) {
        throw new Error(
          "La date de début doit être avant ou égale à la date de fin"
        );
      }

      // Tableau pour stocker les dates
      this.eventForm.eventDetails = [];

      // Boucler entre les deux dates
      while (start.isBefore(end)) {
        //|| start.isSame(end)
        //this.eventForm.eventDetails.push(start.format('YYYY-MM-DD'));
        if (this.planningSettingsSlot) {
          this.planningSettingsSlot.forEach((shortLabel, index) => {
            this.eventForm.eventDetails.push({
              Date: new Date(start.format("YYYY-MM-DD")),
              StartTimeSlot: new Date(start),
              EndTimeSlot: new Date(start),
              ShortLabel: shortLabel.shortLabel,
              Duration: index == 0 ? this.eventForm.hoursDay : 0,
            });
          });
        } else {
          this.eventForm.eventDetails.push({
            Date: new Date(start.format("YYYY-MM-DD")),
            StartTimeSlot: new Date(start),
            EndTimeSlot: new Date(start),
            ShortLabel: "J",
            Duration: this.eventForm.hoursDay,
          });
        }
        start = start.add(1, "day"); // Ajouter un jour à la date actuelle
      }
      this.$forceUpdate();
    },
    addHourAllDays(shortLabel) {
      this.eventForm.eventDetails
        .filter((el) => el.ShortLabel == shortLabel)
        .forEach((el) => {
          if (el.Duration < 24) el.Duration += this.planningSettingTimeStep;
        });
    },
    removeHourAllDays(shortLabel) {
      this.eventForm.eventDetails
        .filter((el) => el.ShortLabel == shortLabel)
        .forEach((el) => {
          if (el.Duration >= this.planningSettingTimeStep)
            el.Duration -= this.planningSettingTimeStep;
        });
    },
    changeDate(args) {
      const diff = dayjs(this.eventForm.endDate).diff(
        dayjs(this.eventForm.startDate),
        "hours",
        true
      );
      if (diff >= 24) {
        this.eventForm.duration = diff;
        this.eventForm.hoursDay = 7;
      } else {
        this.eventForm.duration = diff;
        this.eventForm.hoursDay = diff;
      }
      this.generateEventDetails();
      this.$forceUpdate();
      setTimeout(() => {
        this.endDateLoaded = true;
      }, 100);
    },
    sendEmail(str) {
      let email = str.replace(/\s+/g, "");
      let notification =
        this.eventForm.notificationSmsText &&
        this.eventForm.notificationSmsText.length > 0
          ? encodeURIComponent(this.eventForm.notificationSmsText)
          : "";
      window.open(
        "mailto:" +
          email +
          "?Subject=Notification ORISIS Planning&Body=" +
          notification,
        "_blank"
      );
    },
    sendWhatApp(str) {
      let phoneNumber = str.replace(/\s+/g, "").slice(1);
      let notification =
        this.eventForm.notificationSmsText &&
        this.eventForm.notificationSmsText.length > 0
          ? encodeURIComponent(this.eventForm.notificationSmsText)
          : "";
      window.open(
        "https://wa.me/" + phoneNumber + "?text=" + notification,
        "_blank"
      );
    },
    sendSms() {
      let collaboratorIds = this.eventForm.collaborators.map((el) => el.id);
      let text = this.eventForm.notificationSmsText;
      var localISOTime = new Date(
        timezoneSchedule.removeLocalOffset(
          new Date(this.eventForm.notificationSmsDate)
        )
      ).toISOString();

      if (!this.eventRecord.UpdatedBy) {
        this.cacheSms = {
          collaboratorIds: collaboratorIds,
          text: text,
          sendAt: localISOTime,
          eventId: this.eventRecord.UpdatedBy ? this.eventRecord.id : null,
        };
        this.eventForm.notificationSmsSuccess = true;
      } else {
        this.programSms({
          sms: {
            collaboratorIds: collaboratorIds,
            text: text,
            sendAt: localISOTime,
            eventId: this.eventRecord.UpdatedBy ? this.eventRecord.id : null,
          },
        }).then(() => {
          this.eventForm.notificationSmsSuccess = true;
          this.getHistorySmsByEventId({
            eventId: this.eventRecord.id,
          });
        });
      }
    },
    newCompany() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
    newAffair() {
      this.$router.push({
        name: "new-affair",
        params: {
          id: 0,
          companyId: this.eventForm.company.id,
          title: "Nouvelle affaire",
          tips: "Nouvelle affaire",
          routeOrigine: "affairs",
        },
      });
    },
    removeEvent() {
      var me = this;
      // Mark this event as a real one
      this.eventRecord.isCreating = false;
      this.eventStore.remove(me.eventRecord);

      // Close the editor
      me.editorShown = false;
      this.$emit("close");
    },
    closeDialog() {
      var me = this;
      // this.eventRecord.isCreating = false;
      me.editorShown = false;
      this.$emit("close");
    },
    saveHandler() {
      var me = this;
      let ressourcesIds = [...this.eventForm.ressources];

      if (
        !me.eventForm.affairId &&
        me.resourceId &&
        me.resourceTypeLine === 1
      ) {
        me.eventForm.affairId = me.resourceId;
        const affairResource = me.planningView.linePositions.find(
          (el) => el.typeLine === 1 && el.affairId === me.resourceId
        );
        if (affairResource && !ressourcesIds.includes(affairResource.id)) {
          ressourcesIds.push(affairResource.id);
        }
      } else if (me.eventForm.affairId) {
        const affairResource = me.planningView.linePositions.find(
          (el) => el.typeLine === 1 && el.affairId === me.eventForm.affairId
        );
        if (affairResource && !ressourcesIds.includes(affairResource.id)) {
          ressourcesIds.push(affairResource.id);
        }
      }

      if (ressourcesIds.length > 0) {
        this.eventRecord.assign(ressourcesIds, true);
      }

      let isNew = true;
      if (this.eventRecord.UpdatedBy) {
        isNew = false;
      }
      this.eventRecord.isCreating = false;

      const dataStandard = {
        name: me.eventForm.name,
        startDate: me.eventForm.startDate,
        endDate: me.eventForm.endDate,
        eventDetails: me.eventForm.eventDetails,
        cls: me.eventForm.cls,
        style: me.eventForm.cls,
        EventTypeId: me.eventForm.eventTypeId,
        Comments: me.eventForm.comments,
        IsHidden: me.eventForm.isHidden,
        IsLocked: me.eventForm.isLocked,
        direction: "Forward",
        manuallyScheduled: false,
        constraintType: null,
        constraintDate: null,
        percentDone: 0,
        inactive: null,
        showInTimeline: false,
        preamble: null,
        postamble: null,
        UpdatedBy: this.connectedUser.id,
        HoursDay: Number(this.eventForm.hoursDay),
      };

      if (me.eventForm.affairId > 0) {
        this.eventRecord.AffairId = me.eventForm.affairId;
        this.eventRecord.CompanyId = me.eventForm.companyId;
        let dataEvent = {
          ...dataStandard,
          AffairId: me.eventForm.affairId,
          CompanyId: me.eventForm.companyId,
        };
        if (me.eventForm.missionId && me.eventForm.missionId.length > 0) {
          dataEvent = {
            ...dataEvent,
            MissionId: parseInt(me.eventForm.missionId[0]),
          };
          this.eventRecord.set(dataEvent);
        } else {
          this.eventRecord.set(dataEvent);
        }
      } else {
        this.eventRecord.set(dataStandard);
      }
      this.eventRecord.name = me.eventForm.name;
      this.eventRecord.startDate = me.eventForm.startDate;
      this.eventRecord.endDate = me.eventForm.endDate;
      this.eventRecord.eventDetails = me.eventForm.eventDetails;
      this.eventRecord.cls = me.eventForm.cls;
      this.eventRecord.style = me.eventForm.cls;
      this.eventRecord.EventTypeId = me.eventForm.eventTypeId;
      this.eventRecord.Comments = me.eventForm.comments;
      this.eventRecord.IsHidden = me.eventForm.isHidden;
      this.eventRecord.IsLocked = me.eventForm.isLocked;
      this.eventRecord.direction = "Forward";
      this.eventRecord.manuallyScheduled = false;
      this.eventRecord.constraintType = null;
      this.eventRecord.constraintDate = null;
      this.eventRecord.percentDone = 0;
      this.eventRecord.inactive = null;
      this.eventRecord.showInTimeline = false;
      this.eventRecord.preamble = null;
      this.eventRecord.postamble = null;
      this.eventRecord.UpdatedBy = this.connectedUser.id;
      this.eventRecord.HoursDay = Number(this.eventForm.hoursDay);

      this.eventRecord.setStartEndDate(
        me.eventForm.startDate,
        me.eventForm.endDate
      );

      me.editorShown = false;
      this.$emit("close");
    },
    newEventType() {
      this.$router.push({ path: "/planning/settings/event-types" });
    },
    newCompany() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
  },
};
</script>
<style lang="scss" scoped>
.e-dlg-content {
  padding-bottom: 0px !important;
}
</style>
<style lang="scss">
#dialogTaskEditor .e-dlg-header-content {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom: 0px !important;
}
#dialogTaskEditor .e-dlg-content {
  padding: 0px !important;
}
#dialogTaskEditor .e-footer-content {
  margin-top: 0px !important;
}

.timeline-variant-secondary {
  &.timeline-item-fill-border-secondary {
    border-color: #82868b !important;
    &:last-of-type {
      &:after {
        background: linear-gradient(#82868b, #ffffff);
      }
    }
  }
  .timeline-item-point {
    background-color: #82868b;

    &:before {
      background-color: rgba(#82868b, 0.12);
    }
  }

  .timeline-item-icon {
    color: #82868b;
    border: 1px solid #82868b;
  }
}

.app-timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-left: 1rem;
}
.timeline-item {
  padding-left: 1.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 0.5rem;
    border-left: 1px solid #82868b;
  }

  &:last-of-type {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient(#82868b, #ffffff);
    }
  }
  .timeline-content {
    border-radius: 0.5rem;
    background: #eee;
    padding: 0.5rem;
    color: #343a40;
  }
  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 5px;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: "";
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    z-index: 1;
  }
}
</style>
